<template>
  <div class="layout-footers">
    <div class="footers-center">
      <div class="logo-box">
        <div class="logo" @click="gotoHome"></div>
      </div>
      <div class="footers-message">
        <div class="contact">
          <div class="title">联系我们</div>
          <div class="tel">
            电话 ：<span style="font-family: medium">{{servicePhone}}</span>
          </div>
          <div class="address">地址：安徽省合肥市包河区中铁科技大楼</div>
        </div>
        <div class="about">
          <div class="top">
            <div @click="toAbout">关于我们</div>
            <div @click="openDisclaimer">免责声明</div>
            <div @click="openService">意见反馈</div>
          </div>
          <div class="copy">
            <span>Copyright © 2022</span>
            <!-- <span class="text">安徽数智建造研究院有限公司版权所有</span> -->
            <span class="text">慧决策-建筑企业经营决策支持平台版权所有</span>
          </div>
          <div class="national">
            <div class="icon"></div>
            <div>皖公网安备</div>
            <!-- <div class="national-count">34011102001581号 皖ICP备2021007214号-1</div> -->
            <a
              class="national-count"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34011102001581"
              target="_blank"
            >
              34011102001581号 皖ICP备2021007214号-1
            </a>
          </div>
        </div>
        <div class="code-box code-wei">
          <img
            @dragstart.prevent
            class="code-img"
            src="../../../../public/wx.jpg"
            alt=""
          />
          <div class="code-right">
            <div class="top">扫描二维码</div>
            <!-- <div style="width: 130px">关注安徽数智建造研究院有限公司公众号</div> -->
            <div style="width: 130px">进入小程序</div>
          </div>
        </div>
        <div class="code-box">
          <img
            @dragstart.prevent
            class="code-img"
            src="../../../../public/app.png"
            alt=""
          />
          <div class="code-right">
            <div class="top">扫描二维码</div>
            <div style="width: 130px">下载APP</div>
          </div>
        </div>
        <div class="code-box code-wei">
          <img
            @dragstart.prevent
            class="code-img"
            src="../../../assets/img/layout/gongzhonghao.png"
            alt=""
          />
          <div class="code-right">
            <div class="top">扫描二维码</div>
            <!-- <div style="width: 130px">关注安徽数智建造研究院有限公司公众号</div> -->
            <div style="width: 130px">关注慧决策平台</div>
          </div>
        </div>
        
      </div>
    </div>
    <service-modal :visible="visible"></service-modal>
    <disclaimer-modal ref="disclaimerModal"></disclaimer-modal>
  </div>
</template>

<script>
import ServiceModal from "@/components/FloatNav/serviceModal";
import DisclaimerModal from "@/components/disclaimerModal";
import { mapGetters } from "vuex";
export default {
  components: {
    ServiceModal,
    DisclaimerModal
  },
  data() {
    return {
      visible: false // 客服弹窗是否显示
    };
  },
  computed:{
    ...mapGetters(['servicePhone'])
  },
  mounted() {},
  methods: {
    // 点击logo跳转到首页
    gotoHome() {
      if (this.$route.path != "/home") {
        this.$router.push("/home");
      }
    },
    // 跳转到关于我们页
    toAbout() {
      if (this.$route.path != "/about") {
        this.$router.push("/about");
      }
    },
    // 打开免责声明
    openDisclaimer() {
      this.$refs.disclaimerModal.visible = true;
    },
    // 打开问题反馈弹窗
    openService() {
      this.visible = true;
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
