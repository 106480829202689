<template>
  <div class="layout">
    <headers></headers>
    <div style="height: 70px"></div>

    <div
      class="layout-main"
      :style="{ backgroundColor: !$route.meta.color ? '#FAFCFE' : '#f9fafe' }"
    >
      <router-view></router-view>
    </div>
    <footers></footers>
    <float-nav></float-nav>
  </div>
</template>

<script>
import Headers from "./components/Headers";
import Footers from "./components/Footers";
import FloatNav from "@/components/FloatNav";

export default {
  components: {
    Headers,
    Footers,
    FloatNav,
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
