<template>
	<div class="layout-headers" :class="changeColor ? 'layout-black' : ''">
		<div class="logo-box" @click="gotoHome">
			<div class="headers-icon"></div>
		</div>
		<menus ref="menus"></menus>
		<div class="search">
			<el-input v-model.trim="search" placeholder="请输入搜索内容" maxlength="200" @keyup.enter.native="handleQuery"
				:disabled="searchDisabled"></el-input>
			<span class="iconfont icon-sousuo" :style="{
				cursor: searchDisabled ? 'not-allowed' : '',
				color: changeColor ? '#d7d9db' : '',
			}" @click="handleQuery"></span>
		</div>
		<div class="headers-operation" v-show="!isLogin && !changeColor">
			<el-button @click="goLogin">登录</el-button>
		</div>
		<div class="headers-operation" :class="isMac ? 'headers-operation-mac' : ''" v-show="isLogin"></div>
		<div class="headers-user" :class="isMac ? 'headers-user-mac' : ''" v-show="isLogin">
			<img :src="imgUrl" alt="" />
			<div class="user-name text-ellipsis" :title="userName">
				{{ userName }}
			</div>
			<span class="iconfont icon-shanglajiantou"></span>
			<div class="pull-down">
				<div class="pull-item" @click="goUserInfo" :style="{ color: isUser ? '#1181FA' : '' }">个人中心</div>
				<!-- <div class="pull-item" @click="goSubscribe" :style="{ color: isSubscribe ? '#1181FA' : '' }">自选订阅</div> -->
				<div class="pull-item" @click="outLogin">退出登录</div>
			</div>
		</div>
	</div>
</template>

<script>
// $utils.picturePrefix(user.nickPhoto)
import Menus from "../Menus";
export default {
	components: {
		Menus,
	},
	data() {
		return {
			isLogin: false,
			isSlectCenter: false, // 是否选中个人中心
			changeColor: false, // false 背景蓝色 true背景黑色
			userName: "",
			imgUrl: require("../../../assets/img/user/user-img.png"),
			search: "",
			searchDisabled: false, // 搜索栏是否禁用
			isUser: false, // 是否在会员页面
			isSubscribe: false, // 是否在会员页面
			hide: true,
			isMac: false,//是否mac
		};
	},
	watch: {
		$route() {
			let path = this.$route.path;
			if (path == "/search") {
				this.searchDisabled = true;
			} else {
				this.searchDisabled = false;
			}
			if (path.includes("member")) {
				this.changeColor = true;
			} else {
				this.changeColor = false;
			}
			if (path.includes("user")) {
				this.isUser = true;
			} else {
				this.isUser = false;
			}
			if (path.includes("subscribe")) {
				this.isSubscribe = true;
			} else {
				this.isSubscribe = false;
			}
			if (this.$session.getUsers()) {
				let memberId = this.$session.getUsers() ? this.$session.getUsers().memberId : null;
				if (memberId == 6 || memberId == 5) {
					this.$refs.menus.menus[4].children[0].hide = false;
					this.$refs.menus.menus[4].children[2].hide = false;
					this.$refs.menus.menus[3].children[0].hide = false;
					if (memberId == 5) {
						this.$refs.menus.menus[3].children[0].hide = true;
					}
				} else {
					this.$refs.menus.menus[4].children[0].hide = true;
					this.$refs.menus.menus[4].children[2].hide = true;
					this.$refs.menus.menus[3].children[0].hide = true;
				}
			}
		},
	},
	created() {
		let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
		this.isMac = isMac
	},
	mounted() {
		let path = this.$route.path;
		if (path == "/search") {
			this.searchDisabled = true;
		} else {
			this.searchDisabled = false;
		}
		if (path.includes("member")) {
			this.changeColor = true;
		} else {
			this.changeColor = false;
		}
		if (path.includes("user")) {
			this.isUser = true;
		} else {
			this.isUser = false;
		}
		if (path.includes("subscribe")) {
			this.isSubscribe = true;
		} else {
			this.isSubscribe = false;
		}

		let user = this.$session.getUsers();
		// 判断用户登录
		if (user) {
			this.isLogin = true;
			this.userName = user.consumerName;
			if (user.imgUrl) {
				this.imgUrl = user.imgUrl;
			}
		} else {
			this.isLogin = false;
		}
		// 监听本地存储个人数据的变化
		window.addEventListener("setItemEvent", e => {
			if (e.key == "LOGIN_USERS_KEY") {
				let user = JSON.parse(e.newValue).data;
				this.isLogin = true;
				this.userName = user.consumerName;
				if (user.imgUrl) {
					this.imgUrl = user.imgUrl;
				}
			}
		});
		let memberId = user ? user.memberId : null;
		if (memberId == 6 || memberId == 5) {
			this.$refs.menus.menus[4].children[0].hide = false;
			this.$refs.menus.menus[4].children[2].hide = false;
			this.$refs.menus.menus[3].children[0].hide = false;
			if (memberId == 5) {
				this.$refs.menus.menus[3].children[0].hide = true;
			}
		} else {
			this.$refs.menus.menus[4].children[0].hide = true;
			this.$refs.menus.menus[4].children[2].hide = true;
			this.$refs.menus.menus[3].children[0].hide = true;
		}
	},

	methods: {
		// 点击logo跳转到首页
		gotoHome() {
			if (this.$route.path != "/home") {
				this.$router.push("/home");
			}
		},
		// 点击登录
		goLogin() {
			if (this.$route.path.indexOf("/login") == -1) {
				this.$router.push("/login");
			}
		},
		// 点击搜索
		handleQuery() {
			if (this.$route.path == "/search") {
				return;
			}
			let user = this.$session.getUsers();
			// 判断用户是否有身份
			if (user) {
				if (!user.consumerType) {
					this.$message.error("请选择身份类型");
					return;
				}
			}
			let { search } = this;
			if (search == "") {
				this.$message.error("请输入搜索内容");
				return false;
			}
			let searchData = search;
			this.search = "";
			this.$router.push({
				name: "search",
				params: { data: searchData },
			});
		},

		// 前往用户中心
		goUserInfo() {
			if (this.$route.path.indexOf("user") == -1) {
				let user = this.$session.getUsers();
				// 判断用户是否有身份
				if (user) {
					if (!user.consumerType) {
						this.$message.error("请选择身份类型");
						return;
					}
				}
				this.$router.push("/user");
			}
		},
		// 前往订阅页面
		goSubscribe() {
			if (this.$route.path.indexOf("subscribe") == -1) {
				this.$router.push("/subscribe/list");
			}
		},
		// 退出登录
		outLogin() {
			this.$message.success("已退出登录");
			localStorage.clear();
			this.$router.push("/login");
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
